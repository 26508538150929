import {Page} from "./Page";

class Resources extends Page
{
	constructor ()
	{
		super();


		$('div.resources_wrap').slideUp(0);


		$('button.btn_toggle').on('click', function ( e ) {
			let $btn = $(e.target);
			let $content = $btn.parents('article.resource_group').find('div.resources_wrap');
			if ( !$btn.is('button') )
			{
				$btn = $btn.parents('button.btn_toggle');
			}

			if ( $btn.hasClass( 'is-open' ) )
			{
				$btn.removeClass('is-open');
				$content.stop().slideUp();
			}
			else
			{
				$btn.addClass('is-open');
				$content.stop().slideDown();
			}

		});

	}
}

export {Resources};
import {Page} from './Page.js';
import {Resources} from "./Resources";


class Main
{
	constructor ()
	{
		let strPageId = (Page.getBody()).attr('data-page-id');

		switch ( strPageId )
		{
			case 'resources':
				new Resources();
				break;
			default:
				new Page();
				break;
		}


		(Page.getHtml()).on('fontResize', function (){
			console.debug('fontResize event');

			$('.content_wrapper.slide').removeAttr('style');

		});

	}
}

$(function () {
	new Main();
});
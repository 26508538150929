let $html = null;
let $body = null;
let $window = null;
let iTextSize = 100;

class Page
{
	constructor ()
	{
		this.$textSizeButtons = $('#text_size_selection').find('button.btn_text_size');

		this.$cookieDialog = $('#cookie_dialog');

		this.iWinResizeTimer = 0;

		this.bindEvents();
		this.checkTextSize();
		this.checkCookieAcceptance();

		let mql = (Page.getWindow())[0].matchMedia('(min-width: 1024px)');
		this.validateFontSizeOnWindowResize( mql );
		mql.addListener(this.validateFontSizeOnWindowResize.bind(this));

		this.isIE = ( window.navigator.userAgent.match(/(MSIE|Trident)/) !== null );
		if ( this.isIE )
		{
			let $ieMsg = $('#ie_not_supported');
			$ieMsg.show();
			$ieMsg.removeAttr('aria-hidden');
		}


	}

	bindEvents ()
	{
		this.$textSizeButtons.on('click', this.resizeTexts.bind(this));
		$('#btn_accept_cookie').on('click', (e) => { this.closeCookie(e, true); });
		$('#btn_refuse_cookie').on('click', (e) => { this.closeCookie(e, false); });

		(Page.getWindow()).on('resize', (e) => { this.onWindowResize(e); });
	}

	onWindowResize ( e )
	{
		if ( this.iWinResizeTimer )
		{
			clearTimeout( this.iWinResizeTimer );
		}
		this.iWinResizeTimer = setTimeout(() => {
			$('.content_wrapper.slide').removeAttr('style'); // to remove animation styling that is probably wrong after resize
		}, 100);
	}

	closeCookie ( e, bAccept )
	{
		e.preventDefault();
		this.$cookieDialog.removeClass('is-visible');
		if ( bAccept )
		{
			Cookies.set('cookies_accepted', true, {path: '/'});
		}
		this.$cookieDialog.find('a, button').attr({'aria-hidden': 'true', 'tabindex': '-1'});
	}

	checkCookieAcceptance ()
	{
		let bCookie = Cookies.get('cookies_accepted');
		console.log('checkCookieAcceptance', bCookie);
		if ( !bCookie )
		{
			this.$cookieDialog.addClass('is-visible');
			this.$cookieDialog.find('a, button').removeAttr('aria-hidden tabindex');
		}
		else
		{
			this.$cookieDialog.find('a, button').attr({'aria-hidden': 'true', 'tabindex': '-1'});
		}
	}

	resizeTexts ( _e )
	{
		console.log('resizeTexts');
		let $this = $( _e.target );
		if ( !$this.is('button') )
		{
			$this = $this.parents('button');
		}

		$this.siblings('button').removeClass('is-current');
		$this.addClass('is-current');

		let strSize = $this.attr('data-text-size-class');

		// remove text-size classes
		(Page.getHtml()).removeClass(function (index, className) {
			console.log('className', className);
			return (className.match (/(^|\s)is-text-\S+/g) || []).join(' ');
		});
		(Page.getHtml()).addClass(strSize);

		// garder juste le chiffre pour le reste
		let iSize;
		if ( strSize === '' )
		{
			iSize = 100;
		}
		else
		{
			iSize = parseInt(strSize.replace('is-text-', ''));
		}

		Cookies.set('txt_size', iSize, {path: '/'});
		console.log('send ', strSize);
		(Page.getHtml()).trigger('fontResize', [ iSize ]);
		iTextSize = iSize;
	}

	checkTextSize ()
	{
		let strCookieTextSize = Cookies.get('txt_size');
		console.log ('checkTextSize', strCookieTextSize);
		if ( undefined !== strCookieTextSize )
		{
			console.log('set size from cookie');
			this.$textSizeButtons.filter('.text_size_' + strCookieTextSize).trigger('click');
		}
	}

	validateFontSizeOnWindowResize ( _mql )
	{
		console.log( 'validateFontSizeOnWindowResize' );
		if ( !_mql.matches )
		{
			console.log('+ petit que 1024');
			console.log('iTextSize', iTextSize);
			if ( iTextSize === 200 )
			{
				console.log('resize to 150');
				this.$textSizeButtons.filter('.text_size_150').trigger('click');
			}
		}
	}

	static scrollToEl ( _$el )
	{
		(Page.getHtml()).add(Page.getBody()).animate({
			scrollTop: _$el.offset().top
		}, 2000);
	}

	static getTextSize ()
	{
		return iTextSize;
	}

	static getHtml ()
	{
		if ( $html === null )
		{
			$html = $('html');
		}
		return $html;
	}

	static getBody ()
	{
		if ( $body === null )
		{
			$body = $('body');
		}
		return $body;
	}

	static getWindow ()
	{
		if ( $window === null )
		{
			$window = $(window);
		}
		return $window;
	}
}

export {Page};